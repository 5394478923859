import * as React from "react";
import { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import Logo from "../images/logo-intrander.png";

const Navbar = (props) => {
	return (
		<Popover className="relative z-40">
			<div className="fixed top-0 left-0 right-0 max-w-7xl mx-auto px-4 sm:px-6 bg-white border-b-2 border-gray-100">
				<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<a href="#inicio">
							<span className="sr-only">Workflow</span>
							<img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
						</a>
					</div>
					<div className="-mr-2 -my-2 md:hidden">
						<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
							<span className="sr-only">Open menu</span>
							<MenuIcon className="h-6 w-6" aria-hidden="true" />
						</Popover.Button>
					</div>
					<div className="hidden md:flex items-center justify-center md:flex-1 lg:w-0 gap-6">
						<a
							href="#inicio"
							className="text-base font-medium text-gray-500 hover:text-gray-900"
						>
							Inicio
						</a>
						<a
							href="#caracteristicas"
							className="text-base font-medium text-gray-500 hover:text-gray-900"
						>
							Características
						</a>
						<a
							href="#precios"
							className="text-base font-medium text-gray-500 hover:text-gray-900"
						>
							Precio
						</a>
						<a
							href="#faq"
							className="text-base font-medium text-gray-500 hover:text-gray-900"
						>
							FAQ
						</a>
					</div>
					<div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
						<a
							href={
								"https://wa.me/" +
								props.phone +
								"?text=Hola! Quiero recibir información sobre el sistema para gestionar mi negocio "
							}
							target="_blank"
							className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-brand hover:bg-indigo-900"
						>
							Regístrate ahora
						</a>
					</div>
				</div>
			</div>

			<Transition
				as={Fragment}
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="fixed top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<img className="h-8 w-auto" src={Logo} alt="Workflow" />
								</div>
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<span className="sr-only">Close menu</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
						</div>
						<div className="py-6 px-5 space-y-6">
							<div>
								<p className="mt-6 text-center text-base font-medium text-gray-500">
									<a
										href="#inicio"
										className="text-brand hover:text-indigo-900"
									>
										Inicio
									</a>
								</p>
								<p className="mt-6 text-center text-base font-medium text-gray-500">
									<a
										href="#caracteristicas"
										className="text-brand hover:text-indigo-900"
									>
										Características
									</a>
								</p>
								<p className="mt-6 text-center text-base font-medium text-gray-500">
									<a
										href="#precios"
										className="text-brand hover:text-indigo-900"
									>
										Precio
									</a>
								</p>
								<p className="mt-6 text-center text-base font-medium text-gray-500">
									<a href="#faq" className="text-brand hover:text-indigo-900">
										FAQ
									</a>
								</p>

								<p className="mt-6 text-center text-lg font-semibold text-gray-500">
									<a
										href={
											"https://wa.me/" +
											props.phone +
											"?text=Hola! Quiero recibir información sobre el sistema para gestionar mi negocio "
										}
										target="_blank"
										className="text-brand hover:text-indigo-900"
									>
										Regístrate ahora
									</a>
								</p>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

export default Navbar;
