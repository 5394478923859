import * as React from "react";
import { Link } from "gatsby";
import Navbar from "./navbar";

const Layout = ({ title, phone, children }) => {
	return (
		<div>
			<title>{title}</title>
			<Navbar phone={phone} />
			<main className="relative mt-12 mb-5">
				<div className="max-w-7xl mx-auto">{children}</div>
			</main>
		</div>
	);
};

export default Layout;
