import * as React from "react";

const Feature = ({ svg, title, description }) => {
	return (
		<div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 sm:rounded-xl">
			<div className="p-3 text-white bg-brand rounded-full">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="w-8 h-8 "
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="currentColor"
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					{svg}
				</svg>
			</div>
			<h4 className="text-xl font-medium text-center text-gray-700">{title}</h4>
			<p className="text-base text-center text-gray-500">{description}</p>
		</div>
	);
};

export default Feature;
