import React, { useEffect, useState } from "react";
import Layout from "../layouts/layout";

import hero from "../images/hero.png";
import infoImage from "../images/laptop-woman.jpg";
import Feature from "../components/feature";
import Disclosure from "../components/Disclosure";
import Modal from "../components/Modal";

// markup
const IndexPage = () => {
	const [country, setCountry] = useState("CO");
	const [phone, setPhone] = useState("573160419140");

	useEffect(() => {
		fetch("https://ipapi.co/json/")
			.then((res) => res.json())
			.then((response) => {
				setCountry(response.country);
				if (response.country === "ES") {
					setPhone("34644046940");
				}
			})
			.catch((data, status) => {
				console.log("Country request failed:", data);
			});
	}, []);

	function buyNow() {}

	return (
		<div>
			<Layout title="Intrander" phone={phone}>
				<section id="inicio" className="px-2 py-16 bg-white md:px-0">
					<div className="container items-center px-6 mx-auto xl:px-3">
						<div className="flex flex-wrap items-center sm:-mx-3">
							<div className="w-full md:w-1/2 md:px-3">
								<div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
									<h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
										<span className="block xl:inline">Software para </span>
										<span className="block text-brand xl:inline">
											Gestionar y controlar
										</span>
										<span className="block xl:inline"> tu negocio</span>
									</h1>
									<p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
										Realiza facturas de venta, visualiza el reporte de ventas y
										gastos, divide tus ganancias y agenda citas con tus
										clientes.
									</p>
									<div className="relative flex flex-col sm:flex-row sm:space-x-4">
										<Modal
											buttonClass="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-brand rounded-md sm:mb-0 hover:bg-indigo-900 sm:w-auto"
											buttonText={
												<>
													Demo Gratis
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="w-5 h-5 ml-1"
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													>
														<line x1="5" y1="12" x2="19" y2="12"></line>
														<polyline points="12 5 19 12 12 19"></polyline>
													</svg>
												</>
											}
										/>

										<a
											href="#info"
											className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600"
										>
											Más información
										</a>
									</div>
								</div>
							</div>
							<div className="w-full md:w-1/2">
								<div className="w-full h-auto overflow-hidden rounded-md ">
									<img src={hero} />
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="info" className="py-10 bg-gray-50">
					<div className="items-center max-w-6xl px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
						<div className="flex flex-wrap items-center -mx-3">
							<div className="order-1 w-full px-3 lg:w-1/2 lg:order-0">
								<div className="w-full lg:max-w-md">
									<h2 className="mb-4 text-3xl font-bold leading-tight tracking-tight sm:text-4xl font-heading">
										Incrementa tu productividad
									</h2>
									<p className="mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">
										Herramientas para el día a día que te ayudan a gestionar tu
										negocio de manera cómoda y eficiente.
									</p>
									<ul className="p-0 m-0 leading-6 border-0 border-gray-300">
										<li className="box-border relative py-2 pl-0 text-left font-medium text-gray-500 border-solid">
											<span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-brand rounded-full">
												<span className="text-sm font-bold">✓</span>
											</span>{" "}
											Control de ventas, gastos y ganancias
										</li>
										<li className="box-border relative py-2 pl-0 text-left font-medium text-gray-500 border-solid">
											<span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-brand rounded-full">
												<span className="text-sm font-bold">✓</span>
											</span>{" "}
											Control de empleados e inventario
										</li>
										<li className="box-border relative py-2 pl-0 text-left font-medium text-gray-500 border-solid">
											<span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-brand rounded-full">
												<span className="text-sm font-bold">✓</span>
											</span>{" "}
											Crea variaciones de productos y ofrece servicios
										</li>
										<li className="box-border relative py-2 pl-0 text-left font-medium text-gray-500 border-solid">
											<span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-brand rounded-full">
												<span className="text-sm font-bold">✓</span>
											</span>{" "}
											Accede a tu información desde cualquier lugar
										</li>
									</ul>
								</div>
							</div>
							<div className="w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
								<img
									className="mx-auto sm:max-w-sm lg:max-w-full shadow-lg rounded-xl"
									src={infoImage}
									alt="Información"
								/>
							</div>
						</div>
					</div>
				</section>

				<section className="py-20 lg:py-24 bg-white" id="caracteristicas">
					<div className="container max-w-6xl mx-auto">
						<h2 className="text-4xl font-bold tracking-tight text-center">
							Características
						</h2>
						<p className="mt-2 text-lg text-center text-gray-600">
							Implementa un sistema que se adapta a tus necesidades.
						</p>
						<div className="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">
							<Feature
								title="Facturas de venta"
								description="Emite facturas sobre los productos o servicios que ofreces en tu negocio."
								svg={
									<>
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
										<path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
										<circle cx="6" cy="14" r="3"></circle>
										<path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
									</>
								}
							/>

							<Feature
								title="Reportes"
								description="Analiza los datos de tu negocio con reportes de ventas, gastos y ganancias con gráficos ilustrativos e información detallada de cada transacción."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
										/>
									</>
								}
							/>

							<Feature
								title="Control de inventario"
								description="Conoce en tiempo real todo el inventario de tus productos. Se actualiza automáticamente cuando realizas una venta."
								svg={
									<>
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
										<line x1="12" y1="12" x2="20" y2="7.5"></line>
										<line x1="12" y1="12" x2="12" y2="21"></line>
										<line x1="12" y1="12" x2="4" y2="7.5"></line>
										<line x1="16" y1="5.25" x2="8" y2="9.75"></line>
									</>
								}
							/>

							<Feature
								title="Planificador de citas"
								description="Agenda citas con tus clientes y envía recordatorios, organiza tu agenda y la de tus colaboradores."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
										/>
									</>
								}
							/>

							<Feature
								title="Base de datos de clientes y proveedores"
								description="Registra toda la información de tus clientes y proveedores accesible desde una cómoda interfaz."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
										/>
									</>
								}
							/>

							<Feature
								title="Apertura y cierre de caja"
								description="Control diario de ventas y transacciones con notificaciones vía email del resumen diario de tu negocio ¡No te pierdas ningún detalle!"
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
										/>
									</>
								}
							/>

							<Feature
								title="Atención preferencial y soporte técnico"
								description="Si tienes problemas o dudas ponemos a tu disposición nuestro equipo de ayuda mediante WhatsApp que te guiará ante cualquier problema."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
										/>
									</>
								}
							/>

							<Feature
								title="Úsalo en cualquier lugar"
								description="Accede a tu información, realiza ventas y revisa tus reportes desde cualquier parte del mundo y desde cualquier dispositivo."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
										/>
									</>
								}
							/>

							<Feature
								title="Actualizaciones constantes"
								description="Periodicamente añadimos novedades al sistema para mejorar su usabilidad y tu comodidad. Tu plan incluye todas las actualizaciones y módulos de manera gratuita."
								svg={
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
										/>
									</>
								}
							/>
						</div>
					</div>
				</section>

				<section
					id="precios"
					className="py-8 leading-7 text-gray-900 bg-white sm:py-12 md:py-16 lg:py-24"
				>
					<div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-8 max-w-7xl">
						<div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
							<h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid sm:text-4xl md:text-5xl">
								Implementa Intrander en tu negocio hoy
							</h2>
							<p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl">
								Precio único con capacidades ilimitadas.
							</p>
						</div>
						<div className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0">
							<div className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-brand border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
								<h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
									Plan completo
								</h3>
								<div className="flex flex-col gap-2 items-center mt-6 leading-7 text-gray-900 border-0 border-gray-200">
									{country === "ES" ? (
										<>
											<p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
												€ 60
											</p>
											<p className="box-border m-0 border-solid text-2xl">
												Cada Mes *
											</p>
										</>
									) : (
										<>
											<p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
												$100.000
											</p>
											<p className="box-border m-0 border-solid text-2xl">
												COP / MES *
											</p>
										</>
									)}
								</div>
								<p className="box-bprder mt-2 text-md text-gray-900">
									* Obtén un 17% de descuento con el plan anual.
								</p>
								<p className="mt-6 mb-5 text-base leading-normal text-left text-gray-900 border-0 border-gray-200">
									Ideal para pequeñas y medianas empresas.
								</p>
								<ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
									<li className="inline-flex items-center w-full mb-2 ml-5 pr-5 font-semibold text-left border-solid">
										<span className="text-brand text-lg pr-3">✓</span> Facturas
										ilimitadas
									</li>
									<li className="inline-flex items-center w-full mb-2 ml-5 pr-5 font-semibold text-left border-solid">
										<span className="text-brand text-lg pr-3">✓</span> Usuarios
										ilimitados
									</li>
									<li className="inline-flex items-center w-full mb-2 ml-5 pr-5 font-semibold text-left border-solid">
										<span className="text-brand text-lg pr-3">✓</span> Acceso a
										todos los módulos
									</li>
									<li className="inline-flex items-center w-full mb-2 ml-5 pr-5 font-semibold text-left border-solid">
										<span className="text-brand text-lg pr-3">✓</span> Soporte
										técnico detallado
									</li>
									<li className="inline-flex items-center w-full mb-2 ml-5 pr-5 font-semibold text-left border-solid">
										<span className="text-brand text-lg pr-3">✓</span>{" "}
										Integración de nuevas funcionalidades sin costo adicional
									</li>
								</ul>
								<a
									href={
										"https://wa.me/" +
										phone +
										"?text=Hola! Quiero recibir información sobre el sistema para gestionar mi negocio "
									}
									target="_blank"
									className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-brand border rounded-md cursor-pointer hover:bg-blue-900 hover:border-brand hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg"
								>
									¡Lo quiero!
								</a>
							</div>
						</div>
					</div>
				</section>

				<section
					id="faq"
					className="relative py-16 bg-white min-w-screen animation-fade animation-delay"
				>
					<div className="container px-8 mx-auto sm:px-12 xl:px-5">
						<p className="text-xs font-bold text-left text-pink-500 uppercase sm:mx-6 sm:text-center sm:text-normal sm:font-bold">
							¿Tienes alguna pregunta? Tenemos las respuestas.
						</p>
						<h3 className="mt-1 text-2xl font-bold text-left text-gray-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center">
							Preguntas Frecuentes
						</h3>

						<div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-5">
							<Disclosure title="¿Hay una versión de prueba?">
								¡Sí! Puedes utilizar la versión de prueba haciendo clic aquí
								para saber si el sistema se ajusta a las necesidades de tu
								negocio.
							</Disclosure>

							<Disclosure title="¿Hay algún límite de usuarios/facturas?">
								No. Con Intrander puedes tener todos los usuarios que necesites
								con facturas ilimitadas.
							</Disclosure>

							<Disclosure title="¿Tengo que instalar algún programa?">
								No. Todo nuestro sistema está alojado en la nube y realizamos
								copias de seguridad diarias de tu información. Todos tus datos
								son almacenados de manera segura aplicando protocolos de
								encriptación.
							</Disclosure>

							<Disclosure title="¿Puedo abrirlo en mi celular?">
								¡Sí! Puedes abrir el sistema desde tu celular, computador o
								tablet y la información que visualizarás será en tiempo real
								(Necesitas una conexión estable a internet para utilizar el
								sistema)
							</Disclosure>

							<Disclosure title="¿Hay cláusulas de permanencia?">
								No. Puedes cancelar tu licencia en cualquier momento sin
								penalizaciones.
							</Disclosure>

							{country === "CO" && (
								<Disclosure title="¿Tienen facturación electrónica?">
									Lamentablemente no podemos implementar facturación electrónica
									(al menos de momento) ya que somos una microempresa y no
									contamos con los recursos requeridos por la DIAN. Esperamos
									poder implementarlo en el futuro.
								</Disclosure>
							)}

							<Disclosure title="Necesito una funcionalidad que no está en el sistema, ¿Podrían agregarla?">
								Trabajamos arduamente para mejorar el sistema Intrander y
								estamos dispuestos a implementar cualquier funcionalidad
								faltante para ofrecer un software a la medida a todos nuestros
								clientes. Todo nuestro software ha sido construido escuchando
								las necesidades de nuestros usuarios y lo seguiremos haciendo.
							</Disclosure>

							<Disclosure title="¿Está disponible en otros países?">
								Sí, puedes utilizar el sistema en cualquier país sin
								restricciones. Si en el país en el cual deseas operar hay
								normativas fiscales diferentes a las configuradas en el sistema,
								lo adaptaremos para que puedas utilizarlo.
							</Disclosure>
						</div>
					</div>
				</section>

				<section className="text-gray-700 bg-white body-font">
					<div className="container flex flex-col items-center px-8 py-8 mx-auto max-w-7xl sm:flex-row">
						<a
							href="#_"
							className="text-xl font-black leading-none text-gray-900 select-none logo"
						>
							<span className="text-brand">INTRANDER</span>
						</a>
						<p className="mt-4 text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0">
							© 2020 - {new Date().getFullYear()} | Intrander - Proveedor de
							soluciones de software
						</p>
						<span className="inline-flex justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start">
							<a href="#" className="text-gray-400 hover:text-gray-500">
								<span className="sr-only">Facebook</span>
								<svg
									className="w-6 h-6"
									fill="currentColor"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
										clipRule="evenodd"
									></path>
								</svg>
							</a>

							<a href="#" className="text-gray-400 hover:text-gray-500">
								<span className="sr-only">Instagram</span>
								<svg
									className="w-6 h-6"
									fill="currentColor"
									viewBox="0 0 24 24"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
										clipRule="evenodd"
									></path>
								</svg>
							</a>
						</span>
					</div>
				</section>
			</Layout>
		</div>
	);
};

export default IndexPage;
