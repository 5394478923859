import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

function CustomModal({ buttonText, buttonClass, children }) {
	let [isOpen, setIsOpen] = useState(false);

	function closeModal() {
		setIsOpen(false);
	}

	function openModal() {
		setIsOpen(true);
	}

	return (
		<>
			<div>
				<button
					type="button"
					onClick={openModal}
					className={buttonClass}
				>
					{buttonText}
				</button>
			</div>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-50 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-lg font-medium leading-6 text-gray-900"
								>
									Prueba nuestro sistema gratis
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500">
										Puedes probar todas las funcionalidades del sistema <a className="text-brand hover:text-blue-600 hover:underline" href="https://app.intrander.com/demo" target="_blank">aquí</a>.
									</p>
									<p className="pt-1 text-sm text-gray-600 leading-tight">
										Utiliza los siguientes datos para iniciar sesión:
										<ul className="pt-1">
											<li><span className="font-semibold">Nombre de usuario:</span> admin</li>
											<li><span className="font-semibold">Contraseña:</span> 1234</li>
										</ul>
									</p>
								</div>

								<div className="mt-4 flex justify-center gap-3">
									<a href="https://app.intrander.com/demo" target="_blank"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
										Probar sistema
									</a>
									<button
										type="button"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-gray:ring-blue-500"
										onClick={closeModal}
									>
										Cerrar
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}

export default CustomModal;
