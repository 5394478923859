import React from 'react'
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from '@heroicons/react/outline';

function CustomDisclosure({ title, children }) {
	return (
		<Disclosure>
			{({ open }) => (
				<div className="w-full px-6 py-6 mx-auto bg-white border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow">
					<Disclosure.Button className="text-lg w-full font-bold text-brand sm:text-lg md:text-xl">
						<div className='w-full flex justify-between'>
							<span className="text-left">
								{title}
							</span>
							<ChevronRightIcon 
								className={`h-8 w-8 transition-transform ${open ? "transform rotate-90" : ""}`}
							/>
						</div>
					</Disclosure.Button>

					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<Disclosure.Panel className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
							{children}
						</Disclosure.Panel>
					</Transition>
				</div>
			)}
		</Disclosure>
	);
}

export default CustomDisclosure;
